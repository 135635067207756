import React from 'react';
import {
  Added,
  Fixed,
  Improved,
  Link,
  PageWithSubNav,
  Paragraph,
  PlatformHeader,
} from '../../../../components';
import styles from '../../index.module.scss';

const IndexPage = () => {
  return (
    <PageWithSubNav
      pageType="design"
      title="November 2020"
      subnav="release-notes">
      <div id="November2020" className={styles.release}>
        <Paragraph className="uni-margin--one--vertical">
          This month's update is stuffed with long-time coming Figma library improvements. Ironically it also includes our fair share of housekeeping, although we had no Thanksgiving guests at the table this year.
        </Paragraph>
        <div className={styles.releaseInfo}>
          <div className={styles.platform}>
            <PlatformHeader libName="Docs" />

            <div className={styles.changesList}>
              <Fixed>
                An error when attempting to access component source code via the codeSnippet.
              </Fixed>
              <Fixed>
                The prop title listed when using dismiss in the{' '}
                <Link href="/components/forms/search-input/code">search input</Link>.
              </Fixed>
            </div>
          </div>
          <div className={styles.platform}>
            <PlatformHeader libName="Figma Library" />

            <div className={styles.changesList}>
              <Added>
                All the variants for <Link href="/components/buttons/design">buttons</Link>{' '}
                and <Link href="/components/forms/design">forms</Link>.
              </Added>
              <Added>
                Variants for all <Link href="/components/avatars/design">avatars</Link>{' '}
                and all our <Link href="/components/notifications/design">notification</Link> components.
              </Added>
              <Added>
                Even more variants for all <Link href="/components/dialogs/design">dialog</Link> types.
              </Added>
              <Improved>
                <Link href="/components/notifications/notice/design">Notice</Link> variants
              by providing a responsible subtype for small interfaces.
              </Improved>
            </div>
          </div>
        </div>
      </div>
    </PageWithSubNav>
  );
};

export default IndexPage;
